import React, { useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './Rectangle.css';

const BOX_WIDTH = 700;
const BOX_HEIGHT = 150;

const Rectangle = ({ smooth, vixes }) => {
  const lang = useContext(LocalizedContext);

  const width = BOX_WIDTH;
  const height = BOX_HEIGHT;

  const upperValues = vixes.map(v => v.wins).map(v => -((v/smooth) * (height/2)) + (height/2));
  const lowerValues = vixes.map(v => v.losses).map(v => (v/smooth) * (height/2) + (height/2));
  const polyWidth = width / (vixes.length - 1);

  const upperPoints = vixes.map((_, index) => 
    `${polyWidth * index},${upperValues[index]}`
  ).join(' ')
  const lowerPoints = vixes.map((_, index) => 
    `${polyWidth * (vixes.length - index - 1)},${lowerValues[vixes.length - index - 1]}`
  ).join(' ')
  const polylinePoints = upperPoints + ' ' + lowerPoints;

  const cities = vixes.map(v => v.eventCity);
  const uniqueCities = [];
  const adjustedCities = cities.map(city => {
    if (uniqueCities.includes(city)) {
      return '';
    } else {
      uniqueCities.push(city);
      return city;
    }
  });

  const svgDimensions = '0 0 ' + BOX_WIDTH + ' ' + BOX_HEIGHT;

  return (
    <svg viewBox={svgDimensions}>
      <defs>
        <linearGradient id="rectGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#23345C" />
          <stop offset="50%" stopColor="white" />
          <stop offset="100%" stopColor="#F1BA48" />
        </linearGradient>
      </defs>
      <rect
        width={width}
        height={height}
        fill="url(#rectGradient)"
      />
      <polyline
        points={polylinePoints}
        className='Rectangle'
      />
      <text
        x="50%"
        y="30%"
        textAnchor="middle"
        dominantBaseline="middle"
      >{localize(lang, 'vixWins')}</text>
      <text
        x="50%"
        y="70%"
        textAnchor="middle"
        dominantBaseline="middle"
        >{localize(lang, 'vixLosses')}</text>
      {adjustedCities.map((city, index) => 
        <text
          key={index}
          x={polyWidth * index}
          y="90%"
          fontSize="12"
          fill="#BD8A44"
          textAnchor='left'
          dominantBaseline='middle'
        >{city}</text>
      )}
    </svg>
  );
};

export default Rectangle;