import React, { useState, useCallback, useEffect, useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';
import ReactGA from "react-ga4";

import './FencerDisplay.css';
import '../../essentials/EssentialButton.css';

import Connecting from '../../dataStatus/Connecting';
import NoData from '../../dataStatus/NoData';

import FencerSelector from './FencerSelector';
import TournamentGraph from '../graphs/TournamentGraph';
import InsightsGraph from '../insights/InsightsGraph';
import FinalResults from '../results/FinalResults';
import Elo from '../results/Elo';
import TeamSummary from '../teams/TeamSummary';
import OneSerie from '../series/OneSerie';
import SnapshotDisplay from './SnapshotDisplay';

import callBackend from '../../utils/callBackend';
import search from '../../utils/search';
import hashMaker from '../../utils/hashMaker';
import textComparator from '../../utils/textComparator';
import OpponentDisplay from '../opponent/OpponentDisplay';
import Vix from '../results/Vix';

const RESULT_CACHE_IN_SEC = 120;

const BOUTS_TAB = 'bouts';
const RESULTS_TAB = 'results';
const INSIGHTS_TAB = 'insights';
const OPPONENT_TAB = 'opponent';

const SMOOTH = 3;       // For the Vix graph

const FencerDisplay = props => {
    const [fencers, setFencers] = useState([]);
    const [insights, setInsights] = useState(undefined);
    const [fetchingInsights, setFetchingInsights] = useState(false);
    const [teams, setTeams] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSelecting, setIsSelecting] = useState(false);
    const [selectedTab, setSelectedTab] = useState(BOUTS_TAB);
    const [searchEngine, setSearchEngine] = useState(undefined);
    const [dataValidity, setDataValidity] = useState(0);
    const [snapshots, setSnapshots] = useState([]);
    const [showSnapshots, setShowSnapsots] = useState(false);

    const event = props.event;
    const otherEvents = props.otherEvents;
    const setEventHandler = props.setEventHandler;
    const selectedFencer = props.selectedFencer;
    const isIndividual = props.isIndividual;
    // Pure technical change fencer, for whatever reason
    const selectedFencerHandler = props.selectedFencerHandler;
    const lang = useContext(LocalizedContext);

    const eventName = event.eventPlace.eventName;
    const eventPk = event.eventPk;

    const now = () => {
        return Math.round(new Date().getTime() / 1000);
    }

    // Refreshing the search engine data
    const refresh = () => {
        if( dataValidity + RESULT_CACHE_IN_SEC < now() ) {
            setDataValidity(now); 

            callBackend('results', { checksum: hashMaker(eventName), eventPk }, result => {
                if( result.isOk ) {
                    const fencers = result.data.fencers;
                    setDataValidity(now); 
                    if( fencers.length > 0 ) {
                        setFencers(fencers.sort( (a, b) => a.name.localeCompare(b.name) ));
                        setTeams( result.data.teams );

                        const searchEngine = search();
                        fencers.map( f => searchEngine.addTerm(f.name) );
                        setSearchEngine(searchEngine);
                    }
                }
            });
        }
    }

    const fetchFencersHandler = useCallback( () => {
        setIsLoading(true);
        setError(null);

        callBackend('results', { checksum: hashMaker(eventName), eventPk }, result => {
            if( result.isOk ) {
                const fencers = result.data.fencers;
                setDataValidity(now);
                if( fencers.length > 0 ) {
                    if( ! selectedFencer ) selectedFencerHandler( fencers[0].name );
                    setFencers(fencers.sort( (a, b) => a.name.localeCompare(b.name) ));
                    setTeams( result.data.teams );

                    const searchEngine = search();
                    fencers.map( f => searchEngine.addTerm(f.name) );
                    setSearchEngine(searchEngine);
                }
            } else {
                setError(result.error);
            }
            setIsLoading(false);
        });
    }, [eventName, eventPk, selectedFencer, selectedFencerHandler]);

    useEffect(() => {
        fetchFencersHandler();
    }, [fetchFencersHandler, eventName, eventPk, selectedFencer, selectedFencerHandler] );

    const fetchSnapshotsHandler = useCallback( () => {
        if( selectedFencer ) {
            callBackend('snapshot', { term: selectedFencer, maxResults: 10 }, result => {
                if( result.isOk ) {
                    setSnapshots(result.data);
                }
            });
        }
    }, [selectedFencer]);

    useEffect(() => {
        fetchSnapshotsHandler();
    }, [fetchSnapshotsHandler, selectedFencer] );

    const snapshotButton = (snapshots.length > 0 && isIndividual) ? 
        <button className='EssentialButton' onClick={ () => setShowSnapsots( true ) } >
            {localize(lang, 'exportFencer')}
        </button> : '';

    const fetchInsightsHandler = (fencer, eventPk) => {
        if( !fetchingInsights ) {
            setFetchingInsights(true);
            const req = { fencerName: fencer, eventPk, checksum: hashMaker(fencer + eventPk), smooth: SMOOTH };
            callBackend('analytics', req, result => {
                if( result.isOk && result.data ) {
                    // The fencer could have changed, not checked here.
                    setInsights(result.data);
                }
                setFetchingInsights(false);
            });
        }
    }

    const fencerId = fencer => {
        return fencer.toLowerCase().replaceAll(/[^a-z]/g, '').replaceAll(/[aeiouy]/g, '');
    }

    // User has intentionally changed the fencer
    const fencerSelectedHandler = fencer => {
        if( isSelecting ) setIsSelecting(false);

        selectedFencerHandler(fencer);
        setInsights(undefined);
        if( selectedTab === RESULTS_TAB || selectedTab === INSIGHTS_TAB ) {
            fetchInsightsHandler( fencer, eventPk );
        }

        ReactGA.event( 'select_content', {
            content_type: selectedTab,
            item_id: fencerId(fencer)
        });
        refresh();
    }

    const changeSelectedTab = (e, tab) => {
        e.stopPropagation();
        if( tab !== selectedTab ) {
            setSelectedTab( tab );
            if( !insights && (tab === RESULTS_TAB || tab === INSIGHTS_TAB) ) {
                fetchInsightsHandler( selectedFencer, eventPk );
            }
    
            ReactGA.event( 'select_content', {
                content_type: tab,
                item_id: fencerId(selectedFencer)
            });
        }
    }

    const cancelHandler = () => { setIsSelecting(false) }

    if (isLoading) return <Connecting/>;
    if (fencers.length === 0 || error) return <NoData/>;

    const fencerOptions = isSelecting ? <FencerSelector searchEngine={searchEngine} changeFencer={fencerSelectedHandler} cancelHandler={cancelHandler} /> : '';
    const comparator = textComparator();
    const selectedFencerData = fencers.find( f => comparator.equalTextInAscii(f.name, selectedFencer) );

    const fencer = teams ? localize(lang, 'teamSmall') : localize(lang, 'fencer');

    if( ! selectedFencerData )
        return (
            <div>
                <div className='FencerDisplay-label'>{fencer} {localize(lang, 'statistics')}</div>
                <div className='FencerDisplay-headline'>
                    {selectedFencer}
                    <button className='EssentialButton' onClick={ () => setIsSelecting( true ) } >
                        {localize(lang, 'change')}
                    </button>
                </div>
                <div className='FencerDisplay-text'>
                    {localize(lang, 'noBouts', {'@userid@': selectedFencer, '@eventname@': eventName})}.
                </div>
                {fencerOptions}
                <div>
                    <div className='FencerDisplay-label'>
                        {localize(lang, 'selectEvent')}
                    </div>
                    <div className='FencerDisplay-text'>
                        {localize(lang, 'selectOne')}
                    </div>
                    <OneSerie events={otherEvents} setEventHandler={setEventHandler}/>
                </div>
            </div>
        );

    const closeSnapshotsHandler = () => {
        setShowSnapsots(false);
    }

    if( showSnapshots && snapshots.length > 0 )
        return (<SnapshotDisplay snapshots={snapshots} fencer={selectedFencer} closeHandler={closeSnapshotsHandler} />);

    const tabClassName = st => st === selectedTab ? 'FencerDisplay-selected' : 'FencerDisplay';

    var tabBody = '';
    if( selectedTab === BOUTS_TAB )
        tabBody = <TournamentGraph
                    fencers={fencers} selected={selectedFencerData} changeFencer={fencerSelectedHandler}
                    event={event} otherEvents={otherEvents} setEventHandler={setEventHandler} />;
    else if( selectedTab === OPPONENT_TAB )
        tabBody = <OpponentDisplay fencer={selectedFencer} event={event}/>;
    else if( !insights )
        tabBody = <Connecting/>;
    else if( selectedTab === INSIGHTS_TAB && !teams )
        tabBody = <InsightsGraph fencer={selectedFencer} insights={insights} />;
    else if( selectedTab === INSIGHTS_TAB && teams )
        tabBody = <TeamSummary teamData={teams[selectedFencer]} teamSelectedHandler={fencerSelectedHandler} />;
    else if( selectedTab === RESULTS_TAB )
        tabBody = <div>
            <Elo elos={insights.elos} />
            <Vix smooth={SMOOTH} vixes={insights.vix} />
            <FinalResults fencer={selectedFencer} finalResults={insights.finalResults} />
        </div>;
        
    const insightVsTeams = teams ? localize(lang, 'teamDetails') : localize(lang, 'insights');

    return (
        <div>
            <div className='FencerDisplay-label'>{fencer} {localize(lang, 'statistics')}</div>
            <div className='FencerDisplay-headline'>
                {selectedFencer}
                <button className='EssentialButton' onClick={ () => setIsSelecting( true ) } >
                    {localize(lang, 'change')}
                </button>
                {snapshotButton}
            </div>
            <div className='FencerDisplay-text'>
                {localize(lang, 'displayHelp')}
            </div>
            <div className='FencerDisplay-line'>
                <span className={tabClassName(BOUTS_TAB)} onClick={ e => {changeSelectedTab(e, BOUTS_TAB)} }>{localize(lang, 'bouts')}</span>
                <span className={tabClassName(RESULTS_TAB)} onClick={ e => {changeSelectedTab(e, RESULTS_TAB)} }>{localize(lang, 'results')}</span>
                <span className={tabClassName(INSIGHTS_TAB)} onClick={ e => {changeSelectedTab(e, INSIGHTS_TAB)} }>{insightVsTeams}</span>
                <span className={tabClassName(OPPONENT_TAB)} onClick={ e => {changeSelectedTab(e, OPPONENT_TAB)} }>{localize(lang, 'opponent')}</span>
            </div>
            {fencerOptions}
            {tabBody}
        </div>
    );
}

export default FencerDisplay;