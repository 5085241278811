import React, { useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './Rectangle';
import './Vix.css';
import Rectangle from './Rectangle';

const Vix = ({ smooth, vixes }) => {
    const lang = useContext(LocalizedContext);

    return (
        <div>
            <div className='Vix-label'>Vix</div>
            <div className='Vix-description'>{localize(lang, 'vixDesc')}:</div>
            <Rectangle smooth={smooth} vixes={vixes} />
        </div> );
}

export default Vix;